import React from 'react';
import { clearAllCards } from '../../state/actions';
import { useCard } from '../../state/card-provider';
import { SectionData } from '../data';
import { Section } from './section';

interface SectionListState {
    sections: SectionData[];
}

export const SectionList: React.FC<React.PropsWithChildren<SectionListState>> = ({ sections }) => {
    const { store, dispatch } = useCard();
    
    const onClickClear: React.MouseEventHandler<HTMLDivElement> = () => {
        dispatch(clearAllCards());   
    }

    return (
        <div>
            {
                sections.map((section, index) => <Section key={section.id} section={section} sectionIndex={index} />)
            }
            <div className="footer">
                <div className="button" onClick={onClickClear}>Clear</div>
            </div>
        </div>
    );
}

