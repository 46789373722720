import React from 'react';
import { getType, ActionType } from 'typesafe-actions';
import * as Actions from './actions';
import { data } from '../app/data';

interface Card {
    checks: boolean[];
    notes: string;
}

interface Section {
    name: string;
    cards: Card[];
}

interface CardState {
    sections: Section[];
}

const initialState: CardState = {
    sections: data.sections.map((s) => ({
        name: s.name,
        cards: s.cards.map(c => ({
            checks: [false, false, false],
            notes: '',
        }))
    }))
};

const CardContext = React.createContext<{ store: CardState, dispatch: React.Dispatch<ActionType<typeof Actions>> }>(null!!);

const CARD_STATE = 'card-state';

const saveLocal = (cardState: CardState) => localStorage.setItem(CARD_STATE, JSON.stringify(cardState));
const loadLocal = () => {
    let state = { ...initialState };
    const json = localStorage.getItem(CARD_STATE);
    if (json && json.trim().length) {
        const storedState = JSON.parse(json);
        state = {
            ...state,
            ...storedState,
        }

    }

    return state
}

export const CardProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [store, dispatch] = React.useReducer((state: CardState, action: ActionType<typeof Actions>) => {
        switch (action.type) {
            case getType(Actions.checkCard): {
                const { sectionIndex, cardIndex, checkIndex, checked } = action.payload;
                let sections: Section[] = [
                    ...state.sections,
                ];
                sections[sectionIndex].cards[cardIndex].checks[checkIndex] = checked;
                const _state = {
                    ...state,
                    sections,
                };

                saveLocal(_state);
                return _state;
            }

            case getType(Actions.noteCard): {
                const { sectionIndex, cardIndex, notes } = action.payload;
                let sections: Section[] = [
                    ...state.sections,
                ];
                sections[sectionIndex].cards[cardIndex].notes = notes;

                const _state = {
                    ...state,
                    sections,
                };

                saveLocal(_state);
                return _state;
            }

            case getType(Actions.clearAllCards):
                const _state = {
                    ...state,
                    sections: state.sections.map(section => {
                        return {
                            ...section,
                            cards: section.cards.map(card => {
                                return {
                                    ...card,
                                    notes: '',
                                    checks: [...card.checks.map(() => false)],
                                }
                            })
                        };
                    })
                };
                saveLocal(_state);
                return _state;
            default:
                return { ...state };
        };
    }, loadLocal());

    return (
        <CardContext.Provider value={{ store, dispatch }}>
            {children}
        </CardContext.Provider>
    );
};

export const useCard = () => {
    const context = React.useContext(CardContext);

    if (context === undefined) {
        throw new Error('useCard must be used within a CardProvider.');
    }

    return { ...context };
}
