import React from 'react';
import { SectionList } from './section-list';
import { data } from '../data';
import { CardProvider} from '../../state/card-provider';


export const App: React.FC = () =>
    <div className="content">
        <div className="header">Clue Notebook</div>
        <CardProvider>
            <SectionList sections={data.sections} />
        </CardProvider>
    </div>