import * as React from 'react';
import { SectionData } from '../data';
import { Card } from './card';

export interface SectionProps {
    section: SectionData;
    sectionIndex: number;
}

export const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({ section, sectionIndex }) => {
    return (
        <div className="section">
            <div className="section-header">
                {section.name}
            </div>
            <table>
                <tbody>
                    {section.cards.map((card, index) => <Card key={index} sectionIndex={sectionIndex} card={card} cardIndex={index} />)}
                </tbody>
            </table>
        </div>
    );
}
