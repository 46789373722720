import * as React from 'react';
import { checkCard, noteCard } from '../../state/actions';
import { useCard } from '../../state/card-provider';

interface CardProps {
    card: string;
    sectionIndex: number;
    cardIndex: number;
};

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({ card, cardIndex, sectionIndex }) => {
    const { store, dispatch } = useCard();

    const updateCheck = (checked: boolean, checkIndex: number) => {
        dispatch(checkCard({ sectionIndex, cardIndex, checkIndex, checked }));
    }

    const updateNotes = (notes: string) => {
        dispatch(noteCard({ sectionIndex, cardIndex, notes }));
    }

    const { checks, notes } = store.sections[sectionIndex].cards[cardIndex];

    return (
        <tr>
            <td>{card}</td>
            <td>
                {checks.map((check, index) => {
                    const id = `${card}_check_${index}`;
                    return (
                        [
                            <input key={id} type="checkbox" className="clue-checkbox"
                                id={id}
                                checked={check}
                                onChange={event => updateCheck(event.target.checked, index)}
                            />,
                            <label key={`${id}_label`} htmlFor={id} className="clue-label" />,
                        ]
                    );
                })}
                <input type="text" className="notes"
                    value={notes}
                    onChange={event => updateNotes(event.target.value)}
                />
            </td>
        </tr>
    );
}