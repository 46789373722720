import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app/components/app';

const container = document.getElementById('app');
if (container) {
    createRoot(container).render(<App />);
}
else {
    console.error('HTML Element with id app not found.');
}