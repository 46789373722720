export interface ConfigData {
    "sections" : SectionData[];
}

export interface SectionData {
    id: number;
    name: string;
    cards: string[];
}

export const data: ConfigData =
{
    "sections": [{
            id: 1,
            name: "Suspects",
            cards: [
                "Col. Mustard",
                "Prof. Plum",
                "Mr. Green",
                "Mrs. Peacock",
                "Miss Scarlet",
                "Mrs. White"
            ]
        },
        {
            id: 2,
            name: "Weapons",
            cards: [
                "Knife",
                "Candlestick",
                "Revolver",
                "Lead Pipe",
                "Rope",
                "Wrench"
            ]
        },
        {
            id: 3,
            name: "Rooms",
            cards: [
                "Hall",
                "Lounge",
                "Dining Room",
                "Kitchen",
                "Ball Room",
                "Conservatory",
                "Billiard Room",
                "Library",
                "Study"
            ]
        }
    ]
};
