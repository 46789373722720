import { createAction } from 'typesafe-actions';


interface BaseCardIndexs {
    sectionIndex: number;
    cardIndex: number;
}

export interface CardChecked extends BaseCardIndexs {
    checkIndex: number;
    checked: boolean;
}

export interface NoteCard extends BaseCardIndexs {
    notes: string;
}

export const checkCard = createAction('card/SET_CHECKED', (cardCheck: CardChecked) => cardCheck)();

export const clearAllCards = createAction('card/SET_CLEAR_ACTION')();

export const noteCard = createAction('card/SET_NOTES', (cardCheck: NoteCard) => cardCheck)();
